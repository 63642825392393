
import { Component, Vue } from 'vue-property-decorator'
import { apiFinanceDataCenter } from '@/api/finance'
import DatePicker from '@/components/date-picker.vue'

@Component({
    components: {
        DatePicker,
    }
})
export default class FinanceProfile extends Vue {
    /** S Data **/
    ProfileData: any = {
        partner: {},
        shop: {},
        user: {}
    }

    form = {
        start_time: '', //否	string	开始时间
        end_time: '' //否	string	结束时间
    }
    /** E Data **/

    /** S Methods **/

    // 重置
    onReset() {
        this.form = {
            start_time: '', //否	string	开始时间
            end_time: '' //否	string	结束时间
        }
    
        this.financeDataCenter()
    }

    // 获取财务概况
    financeDataCenter() {
        apiFinanceDataCenter(this.form)
            .then(res => {
                this.ProfileData = res
            })
            .catch(res => { })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.financeDataCenter()
    }
    /** E Life Cycle **/
}
